import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';

import { AccountOpeningRequestComponent } from '@components/account-opening-request/account-opening-request.component';
import { BiometricSignComponent } from '@components/biometric-sign/biometric-sign.component';
import { DropInfoCardComponent } from '@components/drop-info-card/drop-info-card.component';
import { EmailConfirmationComponent } from '@components/email-confirmation/email-confirmation.component';
import { EmployersOpeningRequestComponent } from '@components/employers-opening-request/employers-opening-request.component';
import { ConfirmationModalComponent } from '@components/my-portfolio-components/confirmation-modal/confirmation-modal.component';
import { PortfolioMonthComponent } from '@components/my-portfolio-components/portfolio-month/portfolio-month.component';
import { SuccessfulRequestComponent } from '@components/successful-request/successful-request.component';
import { TwoOptionSelectionComponent } from '@components/two-option-selection/two-option-selection.component';
import { DirectivesModule } from '@directives/directives.module';
import { PipeModule } from '@pipes/pipe.module';
import { PortfolioSummaryComponent } from 'app/modules/post-venta/my-portfolio/portfolio-summary/portfolio-summary.component';
import { LoginModule } from '../login/login.module';
import { SharedModule } from '../shared/shared.module';
import { AffiliateAccountComponent } from './affiliate-account/affiliate-account.component';
import { AffiliateCertificateComponent } from './affiliate-certificate/affiliate-certificate.component';
import { AffiliateContactComponent } from './affiliate-contact/affiliate-contact.component';
import { AffiliatePasswordComponent } from './affiliate-password/affiliate-password.component';
import { AffiliateRequest10Component } from './affiliate-request-10/affiliate-request-10.component';
import { AffiliateSimulatorComponent } from './affiliate-simulator/affiliate-simulator.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { FunctionalitySelectionComponent } from './functionality-selection/functionality-selection.component';
import { MyPortfolioComponent } from './my-portfolio/my-portfolio.component';
import { PostVentaRoutingModule } from './post-venta-routing.module';
import { ProductOpeningComponent } from './product-opening/product-opening.component';
import { QuoteCertificateComponent } from './quote-certificate/quote-certificate.component';
import { SearchAffiliateComponent } from './search-affiliate/search-affiliate.component';
import { SendContractComponent } from './send-contract/send-contract.component';
import { SendPasswordComponent } from './send-password/send-password.component';
import { SendSecurityPasswordComponent } from './send-security-password/send-security-password.component';
import { PortfolioSearchComponent } from './my-portfolio/portfolio-search/portfolio-search.component';
import { PortfolioAffiliateDetailComponent } from './my-portfolio/portfolio-affiliate-detail/portfolio-affiliate-detail.component';
import { InvestorProfileBoxComponent } from './investor-profile-box/investor-profile-box.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComplainModalComponent } from '@components/my-portfolio-components/complain-modal/complain-modal.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PortfolioSearchAffiliateComponent } from './my-portfolio/portfolio-search-affiliate/portfolio-search-affiliate.component';
import { BusinessTubeComponent } from './business-tube/business-tube.component';
import { BusinessTubeSummaryComponent } from './business-tube/business-tube-summary/business-tube-summary.component';
import { BusinessTubeStatusCardComponent } from '@components/business-tube-components/status-card/status-card.component';
import { BusinessTubeClientFormComponent } from './business-tube/client-form/client-form.component';
import { BusinessTubeClientDetailComponent } from './business-tube/client-detail/client-details.component';
import { BusinessTubeClientListComponent } from './business-tube/client-list/client-list.component';
import { BusinessTubeConfirmationComponent } from '@components/business-tube-components/confirmation/confirmation.component';
import { AffiliateEarnedProfitabilityComponent } from './affiliate-earned-profitability/affiliate-earned-profitability.component';
import { ChartistComponent } from '@components/chartist/chartist.component';
import { CertificatesVouchersComponent } from './certificates-vouchers/certificates-vouchers.component';
import { BusinessTubeSearchClientComponent } from './business-tube/search-client/search-client.component';
import { BusinessTubeClientCardComponent } from '@components/business-tube-components/client-card/client-card.component';
import { FilterFormComponent } from '@components/filter-form/filter-form.component';
import { CustomDatesModalComponent } from '@components/modal-custom-dates/modal-custom-dates.component';
import {
  BusinessTubeCloseClientModalComponent
} from '@components/business-tube-components/close-client-modal/close-client-modal.component';

@NgModule({
  declarations: [
    AccountOpeningRequestComponent,
    AffiliateAccountComponent,
    AffiliateComponent,
    AffiliateCertificateComponent,
    AffiliateContactComponent,
    AffiliateSimulatorComponent,
    AffiliatePasswordComponent,
    AffiliateRequest10Component,
    AffiliateEarnedProfitabilityComponent,
    BiometricSignComponent,
    BusinessTubeComponent,
    BusinessTubeClientCardComponent,
    BusinessTubeClientDetailComponent,
    BusinessTubeClientFormComponent,
    BusinessTubeClientListComponent,
    BusinessTubeConfirmationComponent,
    BusinessTubeCloseClientModalComponent,
    BusinessTubeSearchClientComponent,
    BusinessTubeSummaryComponent,
    BusinessTubeStatusCardComponent,
    CertificatesVouchersComponent,
    ChartistComponent,
    ConfirmationModalComponent,
    CustomDatesModalComponent,
    DropInfoCardComponent,
    EmployersOpeningRequestComponent,
    EmailConfirmationComponent,
    FilterFormComponent,
    FunctionalitySelectionComponent,
    ProductOpeningComponent,
    QuoteCertificateComponent,
    SearchAffiliateComponent,
    SendPasswordComponent,
    SendSecurityPasswordComponent,
    SendContractComponent,
    TwoOptionSelectionComponent,
    SuccessfulRequestComponent,
    MyPortfolioComponent,
    PortfolioMonthComponent,
    PortfolioSummaryComponent,
    PortfolioSearchComponent,
    PortfolioSearchAffiliateComponent,
    PortfolioAffiliateDetailComponent,
    InvestorProfileBoxComponent,
    ComplainModalComponent,
  ],
  imports: [
    AgmCoreModule,
    CommonModule,
    DirectivesModule,
    MatStepperModule,
    MatListModule,
    MatButtonModule,
    PostVentaRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatExpansionModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    LoginModule,
    PipeModule,
    SharedModule,
    MatPaginatorModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatBottomSheetModule,
  ],
  providers: [CurrencyPipe, MatDatepickerModule]
})
export class PostVentaModule { }
