// tslint:disable: ban-types
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@interfaces/modalData.interface';

@Component({
  selector: 'app-security-questions-error-modal',
  templateUrl: './security-questions-error-modal.component.html',
  styleUrls: ['./security-questions-error-modal.component.scss'],
})
export class SecurityQuestionsErrorModalComponent {
  public message: string;
  public messageDescription: string;
  public firstBtnText: string;
  public iconName: string;
  public errors: any;

  constructor(
    public dialogRef: MatDialogRef<SecurityQuestionsErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    Object.keys(data).forEach(key => this[key] = data[key]);
    this.errors = data.extraData;
  }

  public primaryAction(): void {
    this.dialogRef.close();
  }
}
