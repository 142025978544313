import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import {
  APV_A_REGIME_BENEFITS, APV_B_REGIME_BENEFITS, APV_DIRECT_DEPOSIT_DISCLAIMER, APV_DISCOUNT_AUTHORIZATION_TEXT,
  CHILE_COUNTRY_CODE, CONTRACT_VALIDITIES, CUSTOM_DATE_FORMATS, ERROR_MESSAGES_FORM, GENERAL_REGIME_DESCRIPTION,
  MONEY_INPUT_TYPES, NO_ILLICIT_ACTIVITY_DECLARATION_TEXT, onServiceError, PESOS_INPUT_CODE, regexNumbersAndCommas,
  regexOnlyNumbers, SEARCH_AFFILIATE_URL, UF_INPUT_CODE
} from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AccountsService } from '@services/accounts/accounts.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { amountValidator } from 'app/validators/amount-validator';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CLIENT_DATA } from 'util/storage.constants';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-account-opening-request',
  templateUrl: './account-opening-request.component.html',
  styleUrls: ['./account-opening-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class AccountOpeningRequestComponent implements OnInit, OnChanges {
  @Input() public selectedOption: 'APV' | 'CAV';
  @Input() public form: FormGroup;
  @Output() public next = new EventEmitter();
  public clientData: ClientDataResponse;
  public firstSegmentText = 'Empleador';
  public secondSegmentText = 'Depósito';
  public selectedSegment = 1;
  public inputTypes = MONEY_INPUT_TYPES;
  public contractValidities = CONTRACT_VALIDITIES;
  public apvRegimeBenefits: string;
  public apvDiscountAuthorizationText = APV_DISCOUNT_AUTHORIZATION_TEXT;
  public noIllicitActivityDeclarationText = NO_ILLICIT_ACTIVITY_DECLARATION_TEXT;
  public date = new FormControl(moment());
  public minLastDiscountDate = new Date(new Date().setMonth(new Date().getMonth() + 2));
  public apvDirectDepositDisclaimer = APV_DIRECT_DEPOSIT_DISCLAIMER;
  public isRegimeSelected = false;
  public otherFundOriginSelected = false;
  public incomeSourceDescriptionMaxLength = 50;
  public incomeSourceDescriptionMinLength = 5;
  public discountForm;
  public firstDiscountDateDefault;
  public restrictions = {
    U: 'El monto debe ser mayor o igual a 0,10 UF',
    P: 'El monto debe ser mayor o igual a $2.000',
    R: 'El monto debe ser entre 1% y 80%',
  };
  public generalRegimeDescription = GENERAL_REGIME_DESCRIPTION;
  public conditionText: string;
  public depositId = 2;
  public discountId = 1;
  public clientActivities;
  public jobTitles;

  public get firstDiscountDate(): AbstractControl { return this.form.get('firstDiscountDate'); }
  public get lastDiscountDate(): AbstractControl { return this.form.get('lastDiscountDate'); }
  public get regime(): AbstractControl { return this.form.get('regime'); }
  public get incomeSource(): AbstractControl { return this.form.get('incomeSource'); }
  public get inputType(): AbstractControl { return this.form.get('inputType'); }
  public get inputAmount(): AbstractControl { return this.form.get('inputAmount'); }
  public get contractValidity(): AbstractControl { return this.form.get('contractValidity'); }
  public get country(): AbstractControl { return this.form.get('country'); }
  public get incomeSourceDescription(): AbstractControl { return this.form.get('incomeSourceDescription'); }
  public get nif(): AbstractControl { return this.form.get('nif'); }
  public get liquidIncome(): AbstractControl { return this.form.get('liquidIncome'); }
  public get jobPosition(): AbstractControl { return this.form.get('jobPosition'); }
  public get profession(): AbstractControl { return this.form.get('profession'); }

  private depositUnavailable = {
    message: '¡Atencion!',
    messageDescription: 'Opción temporalmente no disponible'
  };

  constructor(
    private location: Location,
    private modalProvider: ModalProvider,
    private loadingProvider: LoadingProvider,
    private accountsService: AccountsService,
    private router: Router,
    private postVentaService: PostVentaService,
    public util: Util,
  ) { }

  public ngOnInit() {
    this.setValidators();
    this.inputTypes = MONEY_INPUT_TYPES;
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.isApv ? this.getDiscountForm('apv') : this.getDiscountForm('cav');
    this.firstDiscountDateDefault = this.firstDiscountDate.value;
    this.callServices();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedOption) {
      changes.selectedOption.currentValue === 'APV' ? this.handleApvChanges() : this.handleCavChanges();
      this.setValidators();
    }
  }

  private handleApvChanges() {
    this.getDiscountForm('apv');
    this.conditionText = this.apvDiscountAuthorizationText;
  }

  private handleCavChanges() {
    this.getDiscountForm('cav');
    this.conditionText = this.noIllicitActivityDeclarationText;
  }

  public get isApv(): boolean {
    return this.selectedOption === 'APV';
  }

  public get isChileSelected(): boolean {
    return this.country.value === CHILE_COUNTRY_CODE;
  }

  // COMMENTED BY A TEMPORARY FUNCTIONALITY
  // public changeSegmentSelection(event: number) {
  //   this.resetForm();
  //   this.selectedSegment = event;
  //   this.setValidators();
  // }

  public changeSegmentSelection(event: number) {
    this.resetForm();
    if (!this.isApv && event === this.depositId) {
      return this.modalProvider.openGenericErrorModal(this.depositUnavailable);
    }
    this.setValidators();
    this.selectedSegment = event;
  }

  public goBack() {
    this.resetForm();
    this.location.back();
  }

  public chosenYearHandler(normalizedYear: Moment, picker: string) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    picker === 'first' ? this.firstDiscountDate.setValue(ctrlValue) : this.lastDiscountDate.setValue(ctrlValue);
  }

  public chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, picker: string) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    picker === 'first' ? this.firstDiscountDate.setValue(ctrlValue) : this.lastDiscountDate.setValue(ctrlValue);
    datepicker.close();
  }

  public continue() {
    this.next.emit({ step: 2, selectedSegment: this.selectedSegment });
  }

  public updateApvBenefit() {
    this.apvRegimeBenefits = this.regime.value === 'A' ? APV_A_REGIME_BENEFITS : APV_B_REGIME_BENEFITS;
    this.isRegimeSelected = true;
  }

  public checkFundOrigin() {
    this.otherFundOriginSelected = this.incomeSource.value === 'O';
    this.setIncomeSourceDescriptionValidators();
  }

  public getErrorMessage(controlName: string) {
    const control = this.form.controls[controlName];

    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }
    if (control.hasError('maxlength')) {
      return ERROR_MESSAGES_FORM['maxlength'];
    }
    if (control.hasError('minlength')) {
      return ERROR_MESSAGES_FORM['minlength'];
    }
    if (control.hasError('insufficientAmount')) {
      return this.restrictions[this.inputType.value];
    }
    return '';
  }

  public isTemporalContract() {
    return this.contractValidity.value === 'Temporal';
  }

  public getParams(paramType: string) {
    return (this.discountForm && this.discountForm[paramType]) || [];
  }

  public getDiscountForm(accountType: 'apv' | 'cav') {
    this.loadingProvider.showLoading();
    this.accountsService.getDiscountForm(accountType)
      .toPromise()
      .then((response) => this.discountForm = response)
      .catch(error => this.handleServiceError(error))
      .finally(() => this.loadingProvider.hideLoading());
  }

  public removeSymbols(isLiquid: boolean) {
    const inputValue = isLiquid ? this.liquidIncome.value as string : this.inputAmount.value as string;
    if (!inputValue) { return; }
    const regex = isLiquid ? regexOnlyNumbers :
      this.inputType.value === UF_INPUT_CODE ? regexNumbersAndCommas : regexOnlyNumbers;
    const newValue = inputValue.replace(regex, '');
    isLiquid ? this.liquidIncome.setValue(newValue) : this.inputAmount.setValue(newValue);
  }

  public addSymbols(isLiquid: boolean) {
    const inputValue = isLiquid ? this.liquidIncome.value : this.inputAmount.value;
    const inputType = isLiquid ? PESOS_INPUT_CODE : this.inputType.value;
    if (!inputValue) { return; }
    const newInputValue = this.cleanInvalidCharacter(inputType, inputValue);
    const newValue = this.inputAmountFormat(newInputValue, inputType);
    isLiquid ? this.liquidIncome.setValue(newValue) : this.inputAmount.setValue(newValue);
  }

  public resetInputAmount() {
    this.inputAmount.reset();
    this.setInputAmountValidators();
  }

  public handleContract() {
    if (!this.isTemporalContract()) { this.lastDiscountDate.setValue(null); }
    this.setContractValidityValidators();
  }

  private cleanInvalidCharacter(inputType: string, inputValue: string): number {
    if (inputType !== UF_INPUT_CODE) {
      inputValue = inputValue.replace(regexOnlyNumbers, '');
    } else {
      const inputArray = inputValue.replace(regexNumbersAndCommas, '').split(',');
      inputValue = inputArray.shift() + '.' + inputArray.join('');
    }
    return Number(inputValue);
  }

  private inputAmountFormat(inputValue: number, inputType: string) {
    inputValue = inputValue || 0;
    const precision = inputType === UF_INPUT_CODE ? 2 : 0;
    const value = this.util.addNumberPunctuation(inputValue, precision) || 0;

    const formated = {
      U: value + ' UF',
      P: '$' + value,
      R: value + '%',
    };

    return formated[inputType] || '';
  }

  private callServices() {
    this.loadingProvider.showLoading();
    const getClientEconomicActivities = this.postVentaService.getClientEconomicActivities();
    const getJobTitles = this.postVentaService.getJobTitles();
    forkJoin([
      getClientEconomicActivities,
      getJobTitles,
    ]).pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe(([clientActivities, jobTitles]) => {
        this.clientActivities = clientActivities;
        this.jobTitles = jobTitles;
      },
        (error) => this.handleServiceError(error));
  }

  private setIncomeSourceDescriptionValidators() {
    const incomeSourceDescriptionValidation = this.otherFundOriginSelected ? [Validators.required,
    Validators.maxLength(this.incomeSourceDescriptionMaxLength), Validators.minLength(this.incomeSourceDescriptionMinLength)] : null;
    this.incomeSourceDescription.setValidators(incomeSourceDescriptionValidation);
    this.incomeSourceDescription.updateValueAndValidity();
  }

  private resetForm() {
    this.form.reset();
    this.firstDiscountDate.setValue(this.firstDiscountDateDefault);
    this.country.setValue(CHILE_COUNTRY_CODE);
    this.isRegimeSelected = false;
    this.otherFundOriginSelected = false;
  }

  private setValidators() {
    const discountValidaton = this.selectedSegment === this.discountId ? [Validators.required] : null;
    const depositValidation = this.selectedSegment === this.discountId ? null : [Validators.required];

    this.inputType.setValidators(discountValidaton);
    this.contractValidity.setValidators(discountValidaton);
    this.firstDiscountDate.setValidators(discountValidaton);
    this.incomeSource.setValidators(depositValidation);
    this.country.setValidators(depositValidation);

    this.inputType.updateValueAndValidity();
    this.contractValidity.updateValueAndValidity();
    this.firstDiscountDate.updateValueAndValidity();
    this.incomeSource.updateValueAndValidity();
    this.country.updateValueAndValidity();

    this.setIncomeSourceDescriptionValidators();
    this.setContractValidityValidators();
    this.setInputAmountValidators();
    this.setRegimeValidators();
    this.setNifValidators();
    this.setLiquidIncomeValidators();
    this.setJobPositionValidators();
    this.setProfessionValidators();
  }

  private setProfessionValidators() {
    const professionValidation = this.isApv ? null :
      this.selectedSegment === this.depositId ? [Validators.required] : null;
    this.profession.setValidators(professionValidation);
    this.profession.updateValueAndValidity();
  }

  private setJobPositionValidators() {
    const jobPositionValidation = this.isApv ? null :
      this.selectedSegment === this.depositId ? [Validators.required] : null;
    this.jobPosition.setValidators(jobPositionValidation);
    this.jobPosition.updateValueAndValidity();
  }

  private setLiquidIncomeValidators() {
    const liquidIncomeValidation = this.isApv ? null : [Validators.required];
    this.liquidIncome.setValidators(liquidIncomeValidation);
    this.liquidIncome.updateValueAndValidity();
  }

  private setNifValidators() {
    const nifValidation = this.isApv ? null :
      this.selectedSegment === this.discountId ? null :
        this.country.value === CHILE_COUNTRY_CODE ? null : [Validators.required];
    this.nif.setValidators(nifValidation);
    this.nif.updateValueAndValidity();
  }

  private setRegimeValidators() {
    const regimeValidation = this.isApv ? [Validators.required] : null;
    this.regime.setValidators(regimeValidation);
    this.regime.updateValueAndValidity();
  }

  private setInputAmountValidators() {
    const inputType = this.inputType.value;
    const inputAmountValidation = this.selectedSegment === this.discountId ? amountValidator(inputType) : null;
    this.inputAmount.setValidators(inputAmountValidation);
    this.inputAmount.updateValueAndValidity();
  }

  private setContractValidityValidators() {
    const lastDiscountDateValidation = (this.selectedSegment === this.discountId && this.isTemporalContract()) ?
      [Validators.required] : null;
    this.lastDiscountDate.setValidators(lastDiscountDateValidation);
    this.lastDiscountDate.updateValueAndValidity();
  }

  private handleServiceError(error) {
    error = error.error || error;
    if (!error.code) {
      error = onServiceError;
    }
    this.modalProvider.openGenericTwoButtonsRetryError(error)
      .afterClosed().subscribe((response) => {
        if (response) return this.isApv ? this.getDiscountForm('apv') : this.getDiscountForm('cav');
        return this.router.navigateByUrl(SEARCH_AFFILIATE_URL);
      });
  }

}
