import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AFFILIATE_URL, ASSISTED_LOGIN_URL, SEARCH_AFFILIATE_ERROR_MESSAGE, postVentaServiceError } from '@constants';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { Util } from '@util';
import { ValidateRut } from 'app/validators/rut.validator';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { ModalProvider } from '@providers/modal/modal';
import { finalize } from 'rxjs/operators';
import { LoadingProvider } from '@providers/loading/loading';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Subscription, forkJoin } from 'rxjs';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { ClientEmployersResponse } from '@interfaces/clientEmployers.response';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { CLIENT_DATA, FUNCTIONALITY, PENDING_AUTH } from 'util/storage.constants';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';


export type searchStepType = 'initial' | 'error' | 'ok';
@Component({
  selector: 'app-search-affiliate',
  templateUrl: './search-affiliate.component.html',
  styleUrls: ['./search-affiliate.component.scss'],
})
export class SearchAffiliateComponent implements OnInit, OnDestroy {
  public clientData: ClientDataResponse;
  public searchAffiliateForm: FormGroup;
  public loading = true;
  public maxLengthForRut = 12;
  public indexStep: searchStepType = 'initial';
  public showImage = true;
  public name: string;
  public lastName: string;
  public motherLastName: string;
  public clientRut: string;
  public clientEmployers: any;
  public clientDataSubscription: Subscription;
  public clientEmployerEmpty = [{ businessName: '-' }];
  public functionality: string;
  public title: string;
  public titleHighlight: string;
  public subtitle: string;
  public pictureClass: string;
  public currentValidationRef: AngularFireObject<any>;
  public continueAfterSearch = false;

  constructor(
    private authenticationService: AuthenticationService,
    private loadingProvider: LoadingProvider,
    private firebaseDatabase: AngularFireDatabase,
    private formBuilder: FormBuilder,
    private modalProvider: ModalProvider,
    private postVentaService: PostVentaService,
    private router: Router,
    private util: Util,
    private inactivityService: InactivityService

  ) {
    this.searchAffiliateForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
    });
  }

  public searchAffiliate() {
    this.loadingProvider.showLoading();
    const rut = this.util.rutClean(this.searchAffiliateForm.value['rut']);

    const clientData = this.postVentaService.getClient(rut);
    const clientEmployers = this.postVentaService.getClientEmployers(rut);

    this.clientDataSubscription = forkJoin([clientData, clientEmployers]).pipe(
      finalize(() => {
        if (this.continueAfterSearch) return this.goTo('productOpening');
        this.loadingProvider.hideLoading();
      }),
    ).subscribe(
      ([client, employers]: [ClientDataResponse, ClientEmployersResponse[]]) => this.handleSuccess(rut, client, employers),
      (error) => this.handleError(error)
    );
  }

  public async ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    this.setDataPage();
    const clientData = localStorage.getItem(CLIENT_DATA);
    if (clientData) {
      localStorage.removeItem(CLIENT_DATA);
      sessionStorage.removeItem('affiliateIdToken');
    }
    this.loading = false;
  }

  public ngOnDestroy() {
    if (this.clientDataSubscription) this.clientDataSubscription.unsubscribe();
  }

  public async goTo(path: string) {
    localStorage.setItem('path', path);
    if (path === 'affiliate' || path === 'productOpening') return this.router.navigateByUrl(AFFILIATE_URL);
    await this.authenticationService.logout();
    this.router.navigateByUrl(ASSISTED_LOGIN_URL);
  }

  public handleSuccess(rut: string, clientData: ClientDataResponse, clientEmployers: ClientEmployersResponse[]) {
    if (!Object.keys(clientData).length) return this.indexStep = 'error';
    this.indexStep = 'ok';
    this.clientData = clientData;
    clientData.rut = rut;
    localStorage.setItem(CLIENT_DATA, JSON.stringify(clientData));
    this.setClientData(rut, clientData);
    this.setClientEmployers(clientEmployers);
  }

  private setClientData(rut: string, clientData: ClientDataResponse) {
    this.clientRut = rut;
    this.name = clientData.name;
    this.lastName = clientData.lastName;
    this.motherLastName = clientData.motherLastName;
  }

  private setClientEmployers(clientEmployers: ClientEmployersResponse[]) {
    this.clientEmployers = clientEmployers.length ? clientEmployers : this.clientEmployerEmpty;
  }

  public toggleImage(show: boolean) {
    this.showImage = show;
  }

  get clientRutControl() {
    return this.searchAffiliateForm.controls['rut'] as FormControl;
  }

  get disableSearchForm() {
    return this.searchAffiliateForm.invalid || !this.searchAffiliateForm.dirty;
  }
  get errorMessage() {
    return SEARCH_AFFILIATE_ERROR_MESSAGE[this.indexStep];
  }

  private handleError(error) {
    error = error.error || error;

    if (!error.code) {
      error = postVentaServiceError;
    }

    this.modalProvider.openGenericRetryErrorModal(error);
  }

  private setDataPage() {
    const functionality = localStorage.getItem(FUNCTIONALITY);
    this.functionality = functionality;
    if (functionality === 'affiliate') return this.setAffiliateTextOption();
    this.checkPendingAuth();
    this.setproductOpeningTextOption();
  }

  private checkPendingAuth() {
    const pendingAuth = localStorage.getItem(PENDING_AUTH);
    if (!pendingAuth) return;
    this.loadingProvider.showLoading();
    this.continueAfterSearch = true;
    this.currentValidationRef = this.firebaseDatabase.object(`client/${pendingAuth}`);
    this.currentValidationRef.valueChanges().subscribe(async (data) => {
      this.clientRutControl.setValue(data.rut);
      this.searchAffiliate();
    });
  }

  private setAffiliateTextOption() {
    this.title = 'Buscador';
    this.titleHighlight = 'de Afiliados';
    this.subtitle = 'Ingresa el RUT del afiliado que deseas buscar.';
    this.pictureClass = 'search-affiliate-picture';
  }

  private setproductOpeningTextOption() {
    this.title = 'Apertura de';
    this.titleHighlight = 'Productos';
    this.subtitle = 'Ingresa el RUT de la persona para iniciar el proceso de apertura de productos.';
    this.pictureClass = 'search-affiliate-product-opening-picture';
  }
}
