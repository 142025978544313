import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { accountsRank, accountTypeName, EMAIL_PATTERN, fundList, regimeTypeName } from '@constants';
import { FundConfig } from '@interfaces/accountConfigModal.interface';
import { AccountWithAfp, Regime } from '@interfaces/client.interface';
import { ClientRequest, Transfer } from '@interfaces/transfer.interface';
import { SessionProvider } from '@providers/session/session';
import { Util } from '@util';
import * as _ from 'lodash';

@Component({
  selector: 'app-voucher-preview',
  templateUrl: './voucher-preview.component.html',
  styleUrls: ['./voucher-preview.component.scss']
})
export class VoucherPreviewComponent implements OnChanges {
  @Input() public client: ClientRequest;
  @Input() public accountsSelected: Array<AccountWithAfp> = [];
  @Input() public transferStatus: boolean;
  @Input() public voucherData: Transfer;
  @Input() public isPreview: boolean;
  public accountsList: Array<[string, Array<[string, Array<AccountWithAfp>]>]> = [];
  public isNotLoadedData: boolean;
  public regimeDescription = regimeTypeName;
  public extendName = accountTypeName;
  public anyTaxBonusTransfered = false;
  public sendVoucherForm: FormGroup;
  public emailModalInput: MatDialogRef<{}, any>;
  public voucherServiceFailed: boolean;
  public clientRut: string;
  public titles = {
    mandatory: false,
    voluntary: false
  };
  public clientName: string;
  public clientLastNames: string;

  constructor(
    public util: Util,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private sessionProvider: SessionProvider,
  ) {
    this.isNotLoadedData = true;
    this.voucherServiceFailed = false;
    this.sendVoucherForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]]
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.transferIsDone(changes) && this.isNotLoadedData) {
      this.sessionProvider.clearSessionEvents();
      this.loadData();
      this.anyTaxBonusTransfered = this.hasTaxBonus();
      this.isNotLoadedData = false;
    }
  }

  public getTotalSingleAccountTransfered(account: AccountWithAfp) {
    return account.regimes.reduce((total, regime) => total + regime.destinationBalance, 0);
  }

  public getTotalTransfered(accountArray: Array<AccountWithAfp>) {
    return accountArray.reduce((total, account) => total + this.getTotalSingleAccountTransfered(account), 0);
  }

  public openEmailInput(templateRef) {
    this.voucherServiceFailed = false;
    this.sendVoucherForm.controls.email.setValue(this.client.email);
    this.emailModalInput = this.dialog.open(templateRef, { disableClose: true });
  }

  public closeDialog() {
    this.emailModalInput.close();
  }

  public isDistribution(account: AccountWithAfp) {
    return typeof account.regimes[0].destinationFundType !== 'string';
  }

  public getDistributedFunds(account: AccountWithAfp, index: number): FundConfig {
    const sorted = (_.orderBy(account.regimes[0].destinationFundType as FundConfig[], 'type', 'asc'));
    return sorted[index];
  }

  public getDescriptionFund(type: string) {
    return _.find(fundList, { type }).description;
  }

  private transferIsDone(changes: SimpleChanges) {
    return changes['transferStatus'] && changes['transferStatus'].currentValue;
  }

  private loadData() {
    [this.clientRut, this.clientName, this.clientLastNames] = this.util.setClientPersonalData(this.client);
    const mandatoryAccounts = this.getCustomAccounts().filter(account => this.util.isMandatoryAccount(account[0]));
    if (mandatoryAccounts.length) this.accountsList.push(['mandatory', mandatoryAccounts]);
    const voluntaryAccounts = this.getCustomAccounts().filter(account => this.util.isVoluntaryAccount(account[0]));
    if (voluntaryAccounts.length) this.accountsList.push(['voluntary', voluntaryAccounts]);
  }

  private getCustomAccounts(): Array<[string, Array<AccountWithAfp>]> {
    const accountsGrouped = _.groupBy([...this.accountsSelected], 'type');
    const pairKeyValue = _.toPairs(accountsGrouped);
    return _.sortBy(pairKeyValue, account => accountsRank[account[0]]);
  }

  private hasTaxBonus(): boolean {
    const regimeWithTaxBonus = (regime: Regime) => regime.regimeType === 'BF';
    const accountWithTaxBonus = (account: AccountWithAfp) => account.regimes.some(regimeWithTaxBonus);
    return this.accountsSelected.some(accountWithTaxBonus);
  }

}
