import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Util } from '@util';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formatRut]'
})
export class FormatRutDirective implements OnChanges {
  @Input('formatRut') public formControl: FormControl;

  constructor(
    private util: Util,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.formControl && changes.formControl.currentValue.value) {
      this.onChange(changes.formControl.currentValue.value);
    }
  }

  @HostListener('input', ['$event.target.value']) public onChange(typedValue: string) {
    if (!typedValue) return;
    const rut = this.util.rutFormat(typedValue);
    this.formControl.setValue(rut);
  }
}
