/* eslint-disable no-magic-numbers */
export const GRAPH_SUMMARY_MOCKUP = {
  success: [
    {
      response: {
        'periods': ['May ‘17', 'Sep ‘17', 'Ene ‘18', 'May ‘18', 'Sep ‘18'],
        'code': null,
        'mainProducts': ['CCO'],
        'accounts': {
          'CDC': [2646804.0, 2888513.0, 3150984.0, 3482968.0, 3783082.0],
          'CCV': [2393746.0, 2814688.0, 3320538.0, 3745626.0, 4415644.0],
          'CAV': [54361.0, 53974.0, 53837.0, 54151.0, 54977.0],
          'CCO': [92914318.0, 93377278.0, 94457122.0, 95689771.0, 97763137.0],
        },
      },
    },
  ],
  failures: [
    {
      response: {
        'code': 50001,
        'folioNumber': null,
        'messageDescription': 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        'serviceErrorMessage': '',
        'message': 'Error',
      },
    },
  ],
};
