// tslint:disable: ban-types
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OtherAfpsContactModalComponent } from '@components/contact-afps-modal/contact-afps-modal.component';
import { ModalComponent } from '@components/modal/modal.component';
import { CustomModalData, EmailPinModalData, ErrorMessages, ModalData } from '@interfaces/modalData.interface';
import { SecurityQuestionsErrorModalComponent } from '@components/security-questions-error-modal/security-questions-error-modal.component';
import { SendEmailPinModalComponent } from '@components/send-email-pin-modal/send-email-pin-modal.component';
import { ModalContactComponent } from '@components/modal-contact/modal-contact.component';
import { AuthorizedPhonesModalComponent } from '@components/modal-authorized-phones/modal-authorized-phones.component';
import { CustomDatesModalComponent } from '@components/modal-custom-dates/modal-custom-dates.component';
import { Observable } from 'rxjs';

const TABLET_WIDTH = 720;

@Injectable()
export class ModalProvider {
  private modal: MatDialogRef<ModalComponent, any>;
  private otherAfpsContactModal: MatDialogRef<OtherAfpsContactModalComponent, any>;
  private authorizedPhonesComponentModal: MatDialogRef<AuthorizedPhonesModalComponent, any>;
  private customDatesModalComponent: MatDialogRef<CustomDatesModalComponent, any>;
  private securityQuestionsErrorModal: MatDialogRef<SecurityQuestionsErrorModalComponent, any>;
  private innerWidth: number;
  private innerHeight: number;
  private maxErrorLength = 3;

  constructor(public dialog: MatDialog) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  public openGenericErrorModal(messages: ErrorMessages, fn?: any): void {
    const data = {
      ...messages,
      iconName: 'i-alert-large',
      primaryCallback: () => null,
      firstBtnText: 'Aceptar'
    } as ModalData;

    this.openDialog(data, fn);
  }

  public openGenericModal(messages: ErrorMessages, iconName: string, fn?: any): void {
    const data = {
      ...messages,
      iconName,
      primaryCallback: () => null,
      firstBtnText: 'Aceptar'
    } as ModalData;

    this.openDialog(data, fn);
  }

  public openGenericErrorModalWithCallback(messages: ErrorMessages, fn?: any): MatDialogRef<ModalComponent, any> {
    const data = {
      ...messages,
      iconName: 'i-alert-large',
      primaryCallback: () => this.modal.close(true),
      firstBtnText: 'Aceptar'
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, {
      data,
    });

    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;

  }

  public openGenericRetryErrorModal(messages: ErrorMessages): void {
    const data = {
      ...messages,
      iconName: 'i-alert-large',
      primaryCallback: () => null,
      firstBtnText: 'Volver'
    } as ModalData;

    this.openDialog(data);
  }

  public openModalExecutiveReminder(): MatDialogRef<ModalComponent, any> {
    const data = {
      message: '¡Importante!',
      messageDescription: 'Recuerda que necesitas la autorización verbal del cliente para continuar el traspaso irrevocable',
      iconName: 'i-alert-large',
      primaryCallback: () => this.modal.close(true),
      firstBtnText: 'Continuar'
    } as ModalData;
    this.modal = this.dialog.open(ModalComponent, {
      data,
    });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openGenericTwoButtonsRetryError(messages: ErrorMessages): MatDialogRef<ModalComponent, any> {
    const data = {
      ...messages,
      iconName: 'i-alert-large',
      primaryCallback: () => this.modal.close(true),
      firstBtnText: 'Reintentar',
      secondaryCallback: () => this.modal.close(false),
      secondBtnText: 'Volver'
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, {
      data,
    });

    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public cancelRemoteRequestModal(messageDescription: string): MatDialogRef<ModalComponent, any> {
    const data = {
      message: '¿Estás seguro?',
      messageDescription,
      iconName: 'i-alert-large',
      primaryCallback: () => this.modal.close(true),
      firstBtnText: 'Anular solicitud',
      secondaryCallback: () => this.modal.close(false),
      secondBtnText: 'Cancelar',
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, {
      data,
      autoFocus: false,
      width: '380px',
      height: '420px',
      panelClass: 'cancel-phone-transfer-modal-container'
    });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openLoginQuestionErrorModal(messages: ErrorMessages, blockedUser: boolean): MatDialogRef<ModalComponent, any> {
    const data = {
      ...messages,
      iconName: 'i-alert-large',
      primaryCallback: () => this.modal.close(false),
      firstBtnText: 'Volver',
    } as ModalData;

    if (!blockedUser) {
      data.primaryCallback = () => this.modal.close(true);
      data.firstBtnText = 'Reintentar';
      data.secondaryCallback = () => this.modal.close(false);
      data.secondBtnText = 'Volver';
    }
    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openLoginQuestionErrorRetryModal(): MatDialogRef<ModalComponent, any> {
    const data = {
      message: '¡Ha ocurrido un error!',
      messageDescription: 'Es posible que hayas fallado alguna de tus preguntas. Por favor vuelve a intentarlo.',
      iconName: 'i-alert-large',
      primaryCallback: () => this.modal.close(true),
      firstBtnText: 'Reintentar',
      secondaryCallback: () => this.modal.close(false),
      secondBtnText: 'Volver',
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openErrorModalMadatoryAccountWithRejectedReason(fn?: any): void {
    const data = {
      message: 'No puedes transferir esta cuenta obligatoria.',
      messageDescription: 'Debido a que una de tus cuentas obligatorias tiene una razón de rechazo.',
      iconName: 'i-alert-large',
      primaryCallback: () => null,
      firstBtnText: 'Aceptar'
    } as ModalData;

    this.openDialog(data, fn);
  }

  public openCustomErrorModal(messages: ErrorMessages): MatDialogRef<ModalComponent, any> {
    const data = {
      ...messages,
      iconName: 'i-alert-large',
      primaryCallback: () => null,
      firstBtnText: 'Aceptar'
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openErrorTokenInvalid(): MatDialogRef<ModalComponent, any> {
    const data = {
      message: '¡Ups!, ha ocurrido un error con tu conexión.',
      messageDescription: 'Asegurate de estar conectado a internet y vuelve a intentarlo.',
      iconName: 'i-alert-large',
      primaryCallback: () => null,
      firstBtnText: 'Reintentar'
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openSecurityKeyInfoModal(): void {
    if (Boolean(this.modal)) return;
    const data = {
      message: '¿Qué es mi clave de seguridad?',
      messageDescription: `Recuerda que tu clave de seguridad corresponde a la clave
        entregada por tu AFP para gestionar cambios de fondo, apertura de cuentas, etc.`,
      iconName: 'i-lock',
      primaryCallback: () => null,
      firstBtnText: 'Cerrar'
    } as ModalData;

    let maxWidth = '380px';
    if (this.innerWidth < 420) maxWidth = '90%';

    this.modal = this.dialog.open(ModalComponent, {
      maxWidth,
      autoFocus: false,
      data
    });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }

  public openDocumentIdInfoModal(): void {
    const data = {
      message: 'Número de serie de tu cédula',
      messageDescription: 'Dónde encontrar el número de serie en tu cédula de identidad:',
      iconName: 'i-lock',
      primaryCallback: () => null,
      firstBtnText: 'Cerrar',
      images: true,
    } as ModalData;

    let maxWidth = '610px';
    if (this.innerWidth < 630) maxWidth = '90%';

    let height = 'auto';
    if (this.innerHeight < 795) height = '90%';

    this.modal = this.dialog.open(ModalComponent, {
      maxWidth,
      height,
      autoFocus: false,
      data
    });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }

  public openEndContractModal(name: string): MatDialogRef<ModalComponent, any> {
    const data = {
      iconName: 'i-alert-large',
      message: 'Espera un momento',
      messageDescription: `Estás por terminar el contrato actual de Clave de Seguridad de ${name}. ¿Cómo deseas continuar?`,
      primaryCallback: () => this.modal.close(true),
      firstBtnText: 'Terminar contrato',
      secondaryCallback: () => this.modal.close(false),
      secondBtnText: 'Volver'
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openModal(modalData: ModalData): void {
    this.openDialog(modalData);
  }

  public openOtherAfpsContactModal(): void {
    if (this.otherAfpsContactModal) return;

    this.otherAfpsContactModal = this.dialog.open(OtherAfpsContactModalComponent, {
      height: '650px',
      width: '640px',
      autoFocus: false,
    });

    this.otherAfpsContactModal.afterClosed().subscribe(() => this.otherAfpsContactModal = undefined);
  }

  public openAuthorizedPhonesModal(): void {
    let height = '600px';

    if (this.innerWidth < TABLET_WIDTH) height = '550px';

    this.authorizedPhonesComponentModal = this.dialog.open(AuthorizedPhonesModalComponent, {
      height,
      width: '640px',
      autoFocus: false,
    });
    this.authorizedPhonesComponentModal.afterClosed().subscribe(() => this.authorizedPhonesComponentModal = undefined);
  }

  public openCustomDatesModal(): Observable<any> {
    this.customDatesModalComponent = this.dialog.open(CustomDatesModalComponent, {
      autoFocus: false,
    });
    return this.customDatesModalComponent.afterClosed().pipe((response) => {
      this.customDatesModalComponent = undefined;
      return response;
    });
  }

  public openSecurityQuestionsErrorModal(extraData, errorLength: number): MatDialogRef<SecurityQuestionsErrorModalComponent, any> {
    const data = {
      message: '¡Lo sentimos!',
      messageDescription: 'El RUT ingresado presenta uno o más problemas:',
      iconName: 'i-alert-red-primary',
      firstBtnText: 'Cerrar',
      extraData
    } as ModalData;

    this.securityQuestionsErrorModal = this.dialog.open(SecurityQuestionsErrorModalComponent, {
      height: errorLength > this.maxErrorLength ? '650px' : '500px',
      width: '640px',
      autoFocus: false,
      data,
    });

    this.securityQuestionsErrorModal.afterClosed().subscribe(() => this.securityQuestionsErrorModal = undefined);
    return this.securityQuestionsErrorModal;
  }

  public openSendCertificateModal(start: string, email: string, end: string, mainFunction): void {
    const data = {
      message: 'Espera un momento',
      messageDescription: `${start} "${email}". ${end}`,
      iconName: 'i-alert-large',
      primaryCallback: () => mainFunction(),
      secondaryCallback: () => null,
      firstBtnText: 'Enviar',
      secondBtnText: 'Cancelar',
      secondaryBtnOutlined: true,
    } as ModalData;

    this.modal = this.dialog.open(ModalComponent, {
      autoFocus: false,
      data
    });
  }

  public openSendEmailPinModal(email: string) {
    const data = {
      message: '¡Atención!',
      messageDescription: 'Estás por enviar un nuevo código de validación por correo electrónico a:',
      iconName: 'i-lock',
      firstBtnText: 'Enviar',
      secondBtnText: 'Cancelar',
      email,
    } as EmailPinModalData;

    return this.dialog.open(SendEmailPinModalComponent, {
      autoFocus: false,
      data,
    });

  }

  public openCustomModal(data: CustomModalData): MatDialogRef<ModalComponent, any> {
    this.modal = this.dialog.open(ModalComponent, {
      data,
    });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }

  public openCustomContactModal(data): MatDialogRef<ModalComponent, any> {
    this.modal = this.dialog.open(ModalContactComponent, { data });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
    return this.modal;
  }


  public closeModals(): void {
    this.dialog.closeAll();
    this.modal = null;
  }

  private openDialog(data: ModalData, fn?: any) {
    if (Boolean(this.modal)) return;
    this.modal = this.dialog.open(ModalComponent, { data, autoFocus: false });
    this.modal.afterClosed().subscribe(() => {
      this.modal = undefined;
      if (fn) fn('mandatory');
    });
  }
}
