import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  PUBLIC_CERTIFICATE_TYPES,
  QUOTATIONS_PRODUCTS_TYPES_DICT,
  QUOTE_TIME_PUBLIC_FRAMES,
  REMUNERATIONS_TIME_PUBLIC_FRAMES,
  TYPE_ACCOUNTS
} from '@constants';
import { SideMenuItem } from '@interfaces/sideMenuItem.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ValidateRut } from 'app/validators/rut.validator';
import { finalize } from 'rxjs/operators';
import { SendPublicCertificateRequest } from '@interfaces/sendPublicCertificate.interface';
import { format, subMonths } from 'date-fns';
import { Util } from '@util';
import { ErrorMessages as Message } from '@interfaces/modalData.interface';
import { CertificateModel } from '@interfaces/certificate.interface';
import { EXECUTIVE_RUT } from 'util/storage.constants';
import { Location } from '@angular/common';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';


const moment = _rollupMoment || _moment;

export type CertificateTypes = GeneralTypes | TransactionTypes;
export type GeneralTypes = 'progressiveholidays' | 'affiliate' | 'residence' | 'pensionRecords';
export type TransactionTypes = 'contributions' | 'remunerations';

interface DisplayDict {
  type: string;
  display: string;
  message?: string;
}

const SUCCESS_MESSAGE = {
  message: '¡Listo!<br>Hemos enviado el comprobante',
  messageDescription: 'El certificado fue enviado al correo registrado en los datos personales del afiliado'
};

const DEFAULT_ERROR_MESSAGE = {
  message: 'Ocurrió un error inesperado',
  messageDescription: 'Vuelve a internarlo. Si el problema persiste, puedes obtener este comprobante en nuestra sucursal en línea'
};

const NO_PRODUCT_CODE = 40243;

@Component({
  selector: 'app-certificates-vouchers',
  templateUrl: './certificates-vouchers.component.html',
  styleUrls: ['./certificates-vouchers.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CertificatesVouchersComponent implements OnInit {
  public timeFrames: { value: string; display: string; }[];
  public typeAccounts: DisplayDict[] = TYPE_ACCOUNTS;
  public products: DisplayDict[] = QUOTATIONS_PRODUCTS_TYPES_DICT;
  public allCertificates = PUBLIC_CERTIFICATE_TYPES;
  public otherSelected = false;
  public activeButtons = {
    general: false,
    transactional: false,
    transfer: false
  };
  public selectedCertificate = null;
  public categorySelected: string;
  public certificatesForm: FormGroup;
  public maxLengthForRut = 12;
  public toDate = new FormControl(moment());
  public fromDate = new FormControl(moment());
  public sideNavItems: SideMenuItem[] = [];
  public todayCalendar = new Date(Date.now());

  constructor(
    private loadingProvider: LoadingProvider,
    private formBuilder: FormBuilder,
    private modalProvider: ModalProvider,
    private postVentaService: PostVentaService,
    private util: Util,
    private location: Location,
    private inactivityService: InactivityService

  ) {
    this.certificatesForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
    });
  }
  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
  }


  get minFromDate(): Date {
    const minDate = new Date(1981, 0, 1);
    return minDate;
  }

  get minToDate(): Date {
    moment(this.certificatesForm.get('fromDate').value);
    const minToDate = new Date(this.certificatesForm.get('fromDate').value);
    return minToDate;
  }

  get clientRutControl() { return this.certificatesForm.controls['rut']; }

  public back() {
    if (!this.selectedCertificate) return this.location.back();
    this.selectedCertificate = null;
  }

  public clickCategory(category: string) {
    this.activeButtons[category] = !this.activeButtons[category];
  }

  public selectCertificate(category: string, certificate: CertificateModel) {
    this.selectedCertificate = certificate;
    this.categorySelected = category;
    this.buildForm(certificate);
  }

  public handleTimeFrameSelection(selectedTimeFrame: string) {
    this.otherSelected = selectedTimeFrame === 'another';
    this.certificatesForm.removeControl('fromDate');
    this.certificatesForm.removeControl('toDate');
    if (!this.otherSelected) return;
    this.certificatesForm.addControl('fromDate', new FormControl('', [Validators.required]));
    this.certificatesForm.addControl('toDate', new FormControl('', [Validators.required]));
  }

  private buildForm(certificate: CertificateModel) {
    const { type } = certificate;
    const isPensionRecord = type === 'pensionRecords';
    const isTransactional = this.categorySelected === 'transactional';
    const isContribution = type === 'contributions';
    this.otherSelected = null;
    this.certificatesForm.controls['rut'].setValue('');
    this.certificatesForm.controls['rut'].markAsUntouched();
    this.certificatesForm.removeControl('accountType');
    this.certificatesForm.removeControl('timeframe');
    this.certificatesForm.removeControl('product');
    if (isPensionRecord) this.certificatesForm.addControl('accountType', new FormControl('', [Validators.required]));
    if (isTransactional) {
      this.timeFrames = isContribution ? QUOTE_TIME_PUBLIC_FRAMES : REMUNERATIONS_TIME_PUBLIC_FRAMES;
      this.certificatesForm.addControl('timeframe', new FormControl('', [Validators.required]));
    }
    if (isContribution) this.certificatesForm.addControl('product', new FormControl('', [Validators.required]));
  }

  public sendCertificate() {
    this.loadingProvider.showLoading();

    const formValues = {
      ...this.certificatesForm.value,
    };

    const { rut, accountType, timeframe, fromDate, toDate, product } = formValues;

    const [startDate, endDate] = this.categorySelected === 'transactional' ?
      this.getStartEndDates(timeframe.value, fromDate, toDate) : [undefined, undefined];

    const executiveRut = localStorage.getItem(EXECUTIVE_RUT);

    const sendCertificateRequest = {
      rut: this.util.rutClean(rut),
      certificateType: this.selectedCertificate.type,
      productType: product ? product.type : undefined,
      accountType,
      startDate,
      endDate,
      executiveInfo: executiveRut
    } as SendPublicCertificateRequest;

    this.postVentaService.sendPublicCertificates(sendCertificateRequest)
      .pipe(finalize(() => this.loadingProvider.hideLoading()))
      .subscribe(() => this.openModal(SUCCESS_MESSAGE, 'i-check-red'),
        (e) => this.handleServiceError(e.error));
  }

  private handleServiceError(error: any) {
    if (error && error.code === NO_PRODUCT_CODE) {
      const product = this.certificatesForm.controls['product'].value;
      const message = {
        message: `Afiliado no tiene una ${product.message} asociada`,
        messageDescription: 'Puedes intentar con otro producto'
      } as Message;
      return this.openModal(message, 'i-cross-red');
    }
    this.openModal(DEFAULT_ERROR_MESSAGE, 'i-cross-red');
  }

  private getStartEndDates(timeframe: string, fromDate: string, toDate: string): [string, string] {
    let startDate = format(fromDate, 'YYYY-MM-DD');
    let endDate = format(toDate, 'YYYY-MM-DD');
    if (timeframe === 'another') return [startDate, endDate];
    startDate = format(subMonths(this.todayCalendar, Number(timeframe)), 'YYYY-MM-DD');
    endDate = format(this.todayCalendar, 'YYYY-MM-DD');
    return [startDate, endDate];
  }

  private openModal(message: Message, iconName: string) {
    this.modalProvider.openGenericModal(message, iconName);
  }
}

