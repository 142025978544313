import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MONTH_DICT } from '@constants';

@Component({
  selector: 'app-business-tube-close-client-modal',
  templateUrl: './close-client-modal.component.html',
  styleUrls: ['./close-client-modal.component.scss']
})
export class BusinessTubeCloseClientModalComponent {
  public affiliate;
  public monthsForm: FormGroup;
  public months = Object.values(MONTH_DICT);

  constructor(
    public dialogRef: MatDialogRef<BusinessTubeCloseClientModalComponent>,
    private formBuilder: FormBuilder
  ) {
    const today = new Date();
    const month = today.getMonth() + 1;
    this.monthsForm = this.formBuilder.group({
      month: [MONTH_DICT[month]],
    });
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public closeClient() {
    const value = this.monthsForm.controls['month'].value;
    this.dialogRef.close(value);
  }
}
