import { Component, OnInit } from '@angular/core';
import { TRANSFER_SURVIVOR_PENSION_BENEFICIARIES_URI } from '@constants';
import { environment } from '@env';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { ModalProvider } from '@providers/modal/modal';
import { Util } from '@util';

@Component({
  selector: 'app-unassisted',
  templateUrl: './unassisted.component.html',
  styleUrls: ['./unassisted.component.scss']
})
export class UnassistedComponent implements OnInit {
  public loading = true;
  public cards = [
    {
      id: 1,
      iconUrl: './assets/icons/i-quiero-que-me-llamen.svg',
      title: 'Quiero que me llamen',
      description: 'Ingresa aquí para poder contactar a un ejecutivo y realizar el traspaso.',
      redirectTo: 'contactar',
      alt: 'Icono de un ejecutivo de AFP PlanVital',
      imgTitle: 'Traspaso telefónico con un ejecutivo a AFP PlanVital'
    },
    {
      id: 2,
      iconUrl: './assets/icons/i-traspaso-web.svg',
      title: 'Traspaso Web',
      description: 'Ingresa aquí para realizar el proceso de traspaso sin asistencia y de manera online.',
      redirectTo: 'ingresar',
      alt : 'Traspaso por el sitio web a AFP PlanVital',
      imgTitle: 'Icono de un sitio web'
    }
  ];

  public get isMobile(): boolean { return this.util.isTablet; }
  public get publicSiteURL(): string { return `${environment.publicSiteUrl}${TRANSFER_SURVIVOR_PENSION_BENEFICIARIES_URI}`; }


  constructor(
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private modalProvider: ModalProvider,
    public util: Util
  ) {
    this.googleAnalyticsProvider.registerPageView('unassisted');
  }

  public ngOnInit() {
    this.loading = false;
  }

  public openAuthorizedPhonesModal() {
    this.modalProvider.openAuthorizedPhonesModal();
  }

  public redirectToPublicSite() {
    window.open(`${environment.publicSiteUrl}${TRANSFER_SURVIVOR_PENSION_BENEFICIARIES_URI}`, '_blank');
  }
}
