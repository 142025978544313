import {
        AfterViewInit, Component, ElementRef, NgZone,
        ViewChild, ViewEncapsulation, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Commune, Region } from '@interfaces/region.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ClientService } from '@services/client/client.service';
import { Util } from '@util';
import { finalize } from 'rxjs/operators';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { ERROR_MESSAGES_FORM, PHONE_PATTERN, EMAIL_PATTERN, postVentaServiceError, updateClientMessageSuccess } from '@constants';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { ModalProvider } from '@providers/modal/modal';
import { ModalData } from '@interfaces/modalData.interface';
import { CLIENT_DATA } from 'util/storage.constants';

declare var google: any;

@Component({
  selector: 'app-affiliate-contact',
  templateUrl: './affiliate-contact.component.html',
  styleUrls: ['./affiliate-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AffiliateContactComponent implements OnChanges, AfterViewInit {
  @ViewChild('searchContact', { static: false }) public searchElementRef: ElementRef;
  @Input() public clientData: ClientDataResponse;
  @Output() public updateClient = new EventEmitter<ClientDataResponse>();
  public contactAffiliateForm: UntypedFormGroup;
  public regions: Array<Region>;
  public communes: Array<Commune>;
  public latitude: number;
  public longitude: number;
  public address: string;
  public fullAddress: string;
  public serviceError: boolean;
  public minLength = 9;
  public maxLength = 9;
  public scriptLoaded = false;
  @ViewChild('map', { static: false }) public map!: GoogleMap;
  public markerPosition?: google.maps.LatLngLiteral;
  public options: google.maps.MapOptions = { disableDefaultUI: true, clickableIcons: false };
  public center: google.maps.LatLngLiteral = { lat: -33.440906, lng: -70.646985 };
  public zoom = 15;

  constructor(
    private loadingProvider: LoadingProvider,
    private clientService: ClientService,
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: UntypedFormBuilder,
    private modalProvider: ModalProvider,
    private ngZone: NgZone,
    private util: Util,
    private postVentaService: PostVentaService,
  ) {
    
    this.contactAffiliateForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, [Validators.required]],
      lastName: [{ value: '', disabled: true }, [Validators.required]],
      regionCode: [''],
      communeCode: [{ value: '', disabled: true }],
      email: ['', [Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      cellphoneNumber: [0, [Validators.maxLength(this.maxLength),
        Validators.minLength(this.minLength), Validators.pattern(PHONE_PATTERN)]],
      address: [''],
      addressComplement: [''],
    });
  }

  public ngOnChanges() {
    this.loadData();
  }

  public ngAfterViewInit() {
    if (!this.searchElementRef) return;
    if (this.map) {
      this.map.panTo(this.center);
    }
    setTimeout(() => {
      this.scriptLoaded = true;
      this.loadGoogleMaps();
     }, 1000);
  }

  public refreshData() {
    const rut = this.clientData.rut;
    const cleanRut = this.util.rutClean(rut);
    this.postVentaService.getClient(cleanRut).pipe(
      finalize(() => {
        this.handleSuccess();
      }),
    ).subscribe((data) => {
      this.clientData = data;
      this.clientData.rut = rut;
      this.updateClient.emit(this.clientData);
      localStorage.setItem(CLIENT_DATA, JSON.stringify(this.clientData));
    });
  }

  public loadData() {
    this.loadingProvider.showLoading();

    this.clientService.getRegions().pipe(
      finalize(() => this.loadingProvider.hideLoading()),
    ).subscribe(
      (regionsResponse: Array<Region>) => {
        this.regions = regionsResponse;
        this.regionSubscription();
        this.loadPersonalInfo();
        this.serviceError = false;
      },
      () => this.serviceError = true
    );
  }

  get disableContactAffiliateForm() { return this.contactAffiliateForm.invalid || !this.contactAffiliateForm.dirty || !this.contactAffiliateForm.touched; }

  public updateAffiliate() {
    this.loadingProvider.showLoading();
    const { communeCode, email, cellphoneNumber  } = this.contactAffiliateForm.value;
    const address = this.getFullAddress();
    const infoExecutive = localStorage.getItem('infoExecutive');
    const requestUpdateClient = { commune: communeCode, address, email, cellphoneNumber, rut: this.clientData.rut, infoExecutive };

    this.postVentaService.updateClient(requestUpdateClient)
    .pipe(finalize(() => this.loadingProvider.hideLoading()))
    .subscribe(() => this.refreshData(),
      (error) => this.handleError(error));
  }

  private getFullAddress() {
    const baseAddress = this.fullAddress || this.contactAffiliateForm.controls['address'].value || undefined;
    const complementAddress = this.contactAffiliateForm.controls['addressComplement'].value || undefined;
    return this.checkAddressAndComplement(baseAddress, complementAddress);
  }

  private checkAddressAndComplement(baseAddress, complementAddress) {
    if (!baseAddress && complementAddress) return complementAddress;
    if (baseAddress && !complementAddress) return baseAddress;
    if (baseAddress && complementAddress) {
      if (baseAddress.includes(complementAddress)) return baseAddress;
      return `${ baseAddress }, ${ complementAddress }`;
    }
    return '';
  }

  private handleSuccess() {
    const modalData = this.getModalData();
    this.modalProvider.openModal(modalData);
    this.markAsUntouched();
  }

  private getModalData(): ModalData {
    return {
      ...updateClientMessageSuccess,
      iconName: 'i-success',
      primaryCallback: () => null,
      firstBtnText: 'Entendido',
      images: false,
    } as ModalData;
  }

  private handleError(error) {
    error = error.error || error;

    if (!error.code) {
      error = postVentaServiceError;
    }

    this.modalProvider.openGenericRetryErrorModal(error);
  }

  /* istanbul ignore next */
  public loadGoogleMaps() {
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

    autocomplete.setComponentRestrictions({'country': ['CL'] });
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) return;
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.zoom = 15;
        this.markerPosition = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
        if (this.map) {
          this.map.panTo(this.markerPosition);
        }
        this.searchElementRef.nativeElement.value = (this.searchElementRef.nativeElement.value as string).split(',')[0];
        this.fullAddress = this.searchElementRef.nativeElement.value;
      });
    });
  }

  private loadPersonalInfo() {
    const {
      email,
      name,
      lastName,
      motherLastName,
      cellphoneNumber,
    } = this.clientData;

    let {
      address,
      regionCode,
      communeCode,
    } = this.clientData;

    const addressTemp = address.split(',');
    address = addressTemp[0];
    const addressComplement = addressTemp[1] || '';

    const regionSelected = this.regions.find(region => region.code === regionCode);
    regionCode = regionSelected ? regionCode : null;

    this.communes = regionSelected ? regionSelected.communes : [];
    communeCode = this.communes.find(commune => commune.code === communeCode) ? communeCode : null;
    const joinLastName = `${ lastName } ${ motherLastName  || '' }`;
    this.contactAffiliateForm.setValue({
      ...this.contactAffiliateForm.value, name, lastName: joinLastName, regionCode, communeCode,
      email, cellphoneNumber, address, addressComplement
    });
    this.markAsTouched();
  }

  private markAsTouched() {
    Object.keys(this.contactAffiliateForm.controls).forEach((key) => {
      this.contactAffiliateForm.controls[key].markAsTouched();
    });
  }

  private markAsUntouched() {
    Object.keys(this.contactAffiliateForm.controls).forEach((key) => {
      this.contactAffiliateForm.controls[key].markAsUntouched();
    });
  }

  private invalidEmail(controlName: string) {
    const control = this.contactAffiliateForm.controls[controlName];
    return control.hasError('email') || (control.hasError('pattern') && (controlName === 'email' || controlName === 'repeatEmail'));
  }

  private invalidPhone(controlName: string) {
    const control = this.contactAffiliateForm.controls[controlName];
    return control.hasError('minlength') || control.hasError('maxlength') ||
      (control.hasError('pattern') && (controlName === 'cellphoneNumber'));
  }

  public getErrorMessage(controlName: string) {
    const control = this.contactAffiliateForm.controls[controlName];

    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }
    if (control.hasError('mustMatch')) {
      return ERROR_MESSAGES_FORM['mustMatch'];
    }
    if (this.invalidEmail(controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }
    if (this.invalidPhone(controlName)) {
      return ERROR_MESSAGES_FORM['invalidPhone'];
    }
    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }
    return '';
  }

  public async validateEmailDomain() {
    const emailControl = this.contactAffiliateForm.controls['email'];
    const email = this.contactAffiliateForm.value['email'];
    if (this.invalidEmail('email') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  private regionSubscription() {
    this.contactAffiliateForm.get('regionCode').valueChanges
      .subscribe(regionCodeSelected => {
        const updatedRegion = this.regions.find(region => region.code === regionCodeSelected);
        const { communeCode } = this.contactAffiliateForm.controls;
        communeCode.setValue('');
        if (!updatedRegion) {
          this.communes = [];
          communeCode.disable();
          return;
        }
        this.communes = updatedRegion.communes;
        communeCode.enable();
      });
  }

}
