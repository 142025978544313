// tslint:disable: ban-types
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '@interfaces/modalData.interface';
import { format } from 'date-fns';
import moment from 'moment';

@Component({
  selector: 'app-modal-custom-dates.component',
  templateUrl: './modal-custom-dates.component.html',
  styleUrls: ['./modal-custom-dates.component.scss'],
})
export class CustomDatesModalComponent {
  public todayCalendar = new Date(Date.now());
  public customDatesForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CustomDatesModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
    this.customDatesForm = this.formBuilder.group({
      toDate: new FormControl({ value: '', disabled: true }),
      fromDate: new FormControl({ value: '', disabled: true }),
    });
  }

  get invalidForm() {
    const toDate = this.customDatesForm.controls['toDate'].value;
    const fromDate = this.customDatesForm.controls['fromDate'].value;
    return !toDate || !fromDate;
  }

  get minFromDate(): Date {
    const minDate = new Date(1981, 0, 1);
    return minDate;
  }

  get minToDate(): Date {
    moment(this.customDatesForm.get('fromDate').value);
    const minToDate = new Date(this.customDatesForm.get('fromDate').value);
    return minToDate;
  }

  public dialogClose(): void {
    this.dialogRef.close();
  }

  public setDates(): void {
    const toDate = this.customDatesForm.controls['toDate'].value;
    const fromDate = this.customDatesForm.controls['fromDate'].value;
    this.dialogRef.close({ fromDate: format(fromDate, 'DD-MM-YYYY'), toDate: format(toDate, 'DD-MM-YYYY') });
  }
}
