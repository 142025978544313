import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComplainModalComponent } from '@components/my-portfolio-components/complain-modal/complain-modal.component';
import { ConfirmationModalComponent } from '@components/my-portfolio-components/confirmation-modal/confirmation-modal.component';
import { COMMON_QUANTITY_NAMES, PORTFOLIO_PRODUCTS, START_DATE_UNIX_EPOCH } from '@constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { PortfolioAffiliate } from '@interfaces/executivePortfolioResponse.interface';
import { ProductResponse } from '@interfaces/getProductsResponse.interface';
import { InfoExecutivePortfolio } from '@interfaces/infoExecutivePortfolio.interface';
import { MarkAffiliateContactedRequest } from '@interfaces/markAffiliateContactedRequest.interface';
import { PortfolioComplain } from '@interfaces/portfolioComplain.interface';
import { ProfitabilityEmailRequest } from '@interfaces/profitabilityEmailRequest.interface';
import { SecurityKeyStatusresponse } from '@interfaces/securityKeyStatusresponse.interface';
import { AccountsService } from '@services/accounts/accounts.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import { Util } from '@util';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MAE_DIFFERENTIATED_MESSAGES } from 'util/portfolio.constants';
import { DEVICE_TYPE } from 'util/storage.constants';

@Component({
  selector: 'app-portfolio-affiliate-detail',
  templateUrl: './portfolio-affiliate-detail.component.html',
  styleUrls: ['./portfolio-affiliate-detail.component.scss'],
  providers: [MatBottomSheet],
  entryComponents: [ComplainModalComponent]
})
export class PortfolioAffiliateDetailComponent implements OnInit {
  @Input() public executive: InfoExecutivePortfolio;
  @Input() public affiliate: PortfolioAffiliate;
  @Input() public monthSelected: number;
  @Output() public contactedEvent = new EventEmitter();
  public clientForm: FormGroup;
  public securityKeyStatus: string;
  public products: any;
  public client: ClientDataResponse;
  public isBiotablet: boolean;
  public loading = true;
  public emailSent = false;
  public notAvailable = 'No disponible';
  public affiliateComplains: PortfolioComplain[];
  public affiliateComplainsQuantity = 0;

  constructor(
    private accountsService: AccountsService,
    private matDialog: MatDialog,
    private postVentaService: PostVentaService,
    private formBuilder: FormBuilder,
    private bottomSheet: MatBottomSheet,
    public util: Util,
  ) {
    this.clientForm = this.formBuilder.group({
      comments: [''],
    });
  }

  public ngOnInit() {
    this.isBiotablet = localStorage.getItem(DEVICE_TYPE) === 'biotablet';
    this.loadData();
    this.postVentaService.getPortfolioComplains(this.affiliate.rut).subscribe(response => {
      this.affiliateComplains = this.filteringComplains(response);
      this.affiliateComplainsQuantity = this.affiliateComplains.length;
    });
  }

  public saveComments() {
    this.postVentaService.updateInfoAffiliatePortfolio({
      rut: this.affiliate.rut,
      executiveRut: this.executive.rut,
      comments: this.clientForm.controls['comments'].value
    }).subscribe();
  }

  public openWhatsapp() {
    const executiveName = this.getExecutiveName();

    const affiliateName = this.util.capitalizeText(this.affiliate.name.split(' ')[0]);
    // tslint:disable-next-line: max-line-length
    let text = `Hola ${affiliateName} soy ${executiveName} su Ejecutivo(a) Previsional de PlanVital y lo contacto cumpliendo mi compromiso de guiarlo y acompañarlo como Socio De Por Vida, ayudándolo a conocer el sistema y así tomar mejores decisiones para su futuro`;
    if (this.monthSelected in MAE_DIFFERENTIATED_MESSAGES) text = this.getWhatsappMessage(affiliateName, executiveName);
    window.open(`https://api.whatsapp.com/send?phone=+56${this.client.cellphoneNumber}&text=${text}`);

  }

  public callTo() {
    window.open(`tel:+56${this.client.cellphoneNumber}`);
  }

  public markAsContactedModal() {
    const contactedModal = this.matDialog.open(ConfirmationModalComponent, {
      maxWidth: '95vw',
      width: !this.isBiotablet ? '450px' : '95vw',
      height: !this.isBiotablet ? '500px' : '99vh',
      data: this.affiliate,
    });

    contactedModal.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.marAsContacted();
      }
    });
  }

  private getWhatsappMessage(affiliateName: string, executiveName: string): string {
    return MAE_DIFFERENTIATED_MESSAGES[this.monthSelected]
      .replace('[executiveName]', executiveName).replace('[affiliateName]', affiliateName);
  }

  private marAsContacted() {
    const request = {
      affiliateRut: this.affiliate.rut,
      executiveRut: this.executive.rut,
      contactMonth: this.affiliate.permanence,
      executiveCode: this.executive.code
    } as MarkAffiliateContactedRequest;
    this.postVentaService.markAffiliateAsContacted(request).subscribe(() => {
      this.contactedEvent.emit();
    });
  }

  public sendContactEmail() {
    if (this.emailSent) return;
    const executiveName = this.getExecutiveName();

    const request = {
      affiliateName: this.util.capitalizeFirstLetter(this.affiliate.name.split(' ')[0]),
      affiliateEmail: this.client.email,
      executiveName,
      executiveEmail: this.executive.email ? this.executive.email.toLowerCase() : null,
      monthNumber: this.monthSelected
    } as ProfitabilityEmailRequest;


    this.postVentaService.sendContactEmail(request).subscribe(() => {
      this.emailSent = true;
    });
  }

  private loadData() {
    const clientDataService = this.postVentaService.getClient(this.affiliate.rut);
    const securityKeyStatusService = this.postVentaService.getSecurityKeyStatus(this.affiliate.rut);
    const productsBalanceService = this.accountsService.getProductsBalance(this.affiliate.rut);
    const getInfoAffiliatePortfolio = this.postVentaService.getInfoAffiliatePortfolio(this.affiliate.rut, this.executive.rut);

    forkJoin([clientDataService, productsBalanceService, securityKeyStatusService, getInfoAffiliatePortfolio]).pipe(
      finalize(() => this.loading = false),
    ).subscribe(
      ([client, products, securityKeyStatus, infoAffiliatePortfolio]:
        [ClientDataResponse, ProductResponse[], SecurityKeyStatusresponse, any]) => {
        this.client = client;
        this.securityKeyStatus = securityKeyStatus.status || this.notAvailable;
        this.mapProducts(products);
        this.setComments(infoAffiliatePortfolio);
      },
      // TO DO: MANEJO DE ERROR
      (error) => console.log('error', error)
    );
  }

  private setComments(infoAffiliatePortfolio) {
    if (!infoAffiliatePortfolio) return;
    this.clientForm.controls['comments'].setValue(infoAffiliatePortfolio.comments);
  }

  private mapProducts(products: ProductResponse[]) {
    this.products = PORTFOLIO_PRODUCTS.map((productElement) => {
      const productFinded = products.find((product) => productElement === product.type);
      const infoFunds = this.getInfoFunds(productFinded);
      return {
        type: productElement,
        exist: Boolean(productFinded),
        distributed: this.isProductDistributed(productFinded),
        percentages: infoFunds ? infoFunds.map((fund) => fund.percentages) : [],
        funds: infoFunds ? infoFunds.map((fund) => fund.name) : [],
        class: infoFunds ? infoFunds.map((fund) => fund.class) : []
      };
    });
  }

  private isProductDistributed(product: ProductResponse) {
    return product && product.funds.length > 1;
  }

  private getInfoFunds(product) {
    if (!product) return;
    return product.funds.map((fund) => {
      const { name, distribution } = fund;
      return {
        name,
        percentages: distribution,
        class: `fund-${name.toLowerCase()}`,
      };
    });
  }

  public get clientYears(): string {
    if (!this.client.birthdate) return this.notAvailable;
    const birthday = new Date(this.client.birthdate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return `${Math.abs(ageDate.getUTCFullYear() - START_DATE_UNIX_EPOCH)} años`;
  }

  public openBottomSheet(): void {
    const bottomSheetRef = this.bottomSheet.open(ComplainModalComponent);
    bottomSheetRef.instance.complains = this.affiliateComplains;
  }

  private filteringComplains(complains: PortfolioComplain[]): PortfolioComplain[] {
    complains.forEach(complain => complain.formatedDate = Date.parse(complain.fechaNormativa));

    complains.sort((a, b) => b.formatedDate - a.formatedDate);

    return complains.slice(0, 3);
  }

  private getExecutiveName(): string {
    let splittedExecutiveName = this.executive.name.split(' ');
    splittedExecutiveName = splittedExecutiveName.length > COMMON_QUANTITY_NAMES ?
      this.util.mapCompoundNames(this.executive.name) : splittedExecutiveName;
    return this.util.capitalizeText(`${splittedExecutiveName[2]} ${splittedExecutiveName[0]}`);
  }

}
