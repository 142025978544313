import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  ACCOUNT_TYPES_DICT,
  CERTIFICATE_DESCRIPTIONS,
  CUSTOM_DATE_FORMATS,
  QUOTE_CERTIFICATE_ACCOUNT_TYPES,
  QUOTE_CERTIFICATE_MODAL_MESSAGES,
  QUOTE_TIME_BACK_SELECTION,
  QUOTE_TIME_FRAMES
} from '@constants';
import { AccountCert } from '@interfaces/clientData.interface';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';
import { PostVentaService } from '@services/post-venta/post-venta.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { finalize } from 'rxjs/operators';

const moment = _rollupMoment || _moment;

export type CertificateTypes = GeneralTypes | TransactionTypes;
export type GeneralTypes = 'contingency_records' | 'affiliate' | 'vacations';
export type TransactionTypes = 'quotations' | 'remunerations';

@Component({
  selector: 'app-quote-certificate',
  templateUrl: './quote-certificate.component.html',
  styleUrls: ['./quote-certificate.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})

export class QuoteCertificateComponent implements OnInit {
  public timeFrames: string[] = QUOTE_TIME_FRAMES;
  public accounts: Array<{ name: string, value: string }> = [];
  public otherSelected = false;
  public selected: string;
  public accountTypeDisabled = true;
  public toDate = new FormControl(moment());
  public fromDate = new FormControl(moment());
  public certificateType: string;
  public descriptions = CERTIFICATE_DESCRIPTIONS;
  public modalDescriptions = QUOTE_CERTIFICATE_MODAL_MESSAGES;
  public calendarStartDate: Date;
  public todayDate: Date;
  public transactionCertificateType: TransactionTypes;
  public monthsBack = 0;
  public email: string;
  public quoteSelectForm = new FormGroup({
    timeframe: new FormControl('', [Validators.required]),
    productType: new FormControl({ value: '', disabled: true }, [Validators.required]),
  });
  private quotationsCertificateType = 'Cotizaciones';

  constructor(
    private modalProvider: ModalProvider,
    private location: Location,
    private loadingProvider: LoadingProvider,
    private postVenta: PostVentaService,
    private inactivityService: InactivityService

  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    localStorage.setItem('quoteCertificate', 'true');
    localStorage.setItem('originAffiliate', 'quoteCertificate');
    this.certificateType = localStorage.getItem('certificateType');
    this.email = localStorage.getItem('email');
    this.setTransactionCertificateType();
    localStorage.removeItem('certificateType');
    localStorage.removeItem('email');
    this.setDate();
    this.loadAccounts();
    if (this.accounts && this.accounts.length > 1) {
      this.accountTypeDisabled = false;
    }
  }

  public handleSelection(selectedTimeFrame: string) {
    this.otherSelected = selectedTimeFrame === 'Otro';
    if (this.otherSelected) { return; }
    this.monthsBack = QUOTE_TIME_BACK_SELECTION[selectedTimeFrame];
  }

  public openSendCertificateModal() {
    this.modalProvider.openSendCertificateModal(this.modalDescriptions[this.certificateType]['start'], this.email,
      this.modalDescriptions[this.certificateType]['end'], this.showAffiliateCertificate);
  }

  public dateHandler(normalizedDate: Moment, datepicker: MatDatepicker<Moment>, period: string, date: string) {
    const isFrom = date === 'fromDate';
    const isYear = period === 'year';
    const ctrlValue = isFrom ? this.fromDate.value : this.toDate.value;
    isYear ? ctrlValue.year(normalizedDate.year()) : ctrlValue.month(normalizedDate.month());
    isFrom ? this.fromDate.setValue(ctrlValue) : this.toDate.setValue(ctrlValue);

    if (!isYear) {
      datepicker.close();
    }
  }

  public goBack() {
    this.location.back();
  }

  public showAffiliateCertificate = () => {
    const toDate = this.getToDate() as string;
    const fromDate = this.getFromDate() as string;
    const transactionCertificateType = this.transactionCertificateType as string;
    const productType = this.quoteSelectForm.controls['productType'].value;
    localStorage.setItem('toDate', toDate);
    localStorage.setItem('fromDate', fromDate);
    localStorage.setItem('transactionCertificateType', transactionCertificateType);
    localStorage.setItem('productTypeSelected', productType);
    this.goBack();
  }

  public getToDate() {
    if (this.otherSelected) {
      return this.toDate.value.toDate();
    }
    return this.todayDate;
  }

  public getFromDate() {
    if (this.otherSelected) {
      return this.fromDate.value.toDate();
    }
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - this.monthsBack);
    return fromDate;
  }

  private loadAccounts() {
    this.accounts = QUOTE_CERTIFICATE_ACCOUNT_TYPES;
    this.loadingProvider.showLoading();
    this.postVenta.currentAccounts().pipe(
      finalize(() => {
        this.loadingProvider.hideLoading();
      })
    )
      .subscribe(({ accounts }) => {
        if (!this.hasMandatoryAccount(accounts)) {
          this.accounts = this.accounts.filter(({ value }) => value !== ACCOUNT_TYPES_DICT.CCO);
        }
        if (!this.hasAffiliateVolunteerAccount(accounts)) {
          this.accounts = this.accounts.filter(({ value }) => value !== ACCOUNT_TYPES_DICT.CAF);
        }
      }, () => {
        this.accounts = [];
      });
  }

  private setDate() {
    this.todayDate = new Date();
  }

  private setTransactionCertificateType() {
    this.transactionCertificateType = this.certificateType === this.quotationsCertificateType ? 'quotations' : 'remunerations';
  }

  private hasMandatoryAccount(accounts: Array<AccountCert>) {
    return accounts.some(account => account.type === ACCOUNT_TYPES_DICT.CCO);
  }

  private hasAffiliateVolunteerAccount(accounts: Array<AccountCert>) {
    return accounts.some(account => account.type === ACCOUNT_TYPES_DICT.CAF);
  }
}

