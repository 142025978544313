import 'firebase/firestore';

import { ValidateRut } from 'app/validators/rut.validator';
import { first } from 'rxjs/operators';
import { FUNCTIONALITY, PENDING_AUTH } from 'util/storage.constants';

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EMAIL_PATTERN, ERROR_MESSAGES_FORM,
  PHONE_PATTERN, TRANSFER_ORIGINS, TRANSFER_STATES, VALIDATION_OPERATIONS
} from '@constants';
import { ClientPhoneValidation, ClientValidationData } from '@interfaces/client.interface';
import { EmailDomainValidator } from '@providers/emailDomainValidator/emailDomainValidator';
import { LoadingProvider } from '@providers/loading/loading';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';

export type loginStepType = 'login' | 'loginIdentityCard' | 'waitingIdentityCard' |
  'waiting' | 'errorValidation' | 'errorService' | 'ok';

@Component({
  selector: 'app-client-identity-validation',
  templateUrl: './client-identity-validation.component.html',
  styleUrls: ['./client-identity-validation.component.scss']
})

export class ClientIdentityValidationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public validationInfo;
  @Input() public operations;
  @Input() public isBiotabletValidation: boolean;
  @Input() public affiliateRut?: string;
  @Output() public status = new EventEmitter();
  @Output() public userType = new EventEmitter();
  @Output() public clientPhoneValidation = new EventEmitter<ClientPhoneValidation>();
  @Output() public isCodeValidation = new EventEmitter();
  @Output() public isBiometricValidation = new EventEmitter();
  @Output() public isFacialValidation = new EventEmitter();
  @Output() public biotabletValidation = new EventEmitter();
  public biometricOperations = VALIDATION_OPERATIONS;
  public currentValidationRef: AngularFireObject<any>;

  public clientForm: FormGroup;
  public maxLengthForRut = 12;
  public minLength = 9;
  public maxLength = 9;
  public operation = 'default';
  public clientPhoneValidationObject: ClientPhoneValidation = {
    status: 'login',
    timeOut: false,
  } as ClientPhoneValidation;

  constructor(
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: FormBuilder,
    private firebaseDatabase: AngularFireDatabase,
    private authenticationService: AuthenticationService,
    private loadingProvider: LoadingProvider,
    private util: Util,
  ) {
    this.clientForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      operation: ['', [Validators.required]],
      name: [''],
      phone: ['', [Validators.maxLength(this.maxLength), Validators.minLength(this.minLength), Validators.pattern(PHONE_PATTERN)]],
    });
  }

  get clientRutControl() { return this.clientForm.controls['rut']; }

  get disableClientForm() { return this.clientForm.invalid; }

  public ngAfterViewInit() {
    this.checkIfClientIsBeingVerified();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.affiliateRut) {
      this.clientRutControl.setValue(this.util.rutFormat(changes.affiliateRut.currentValue));
      this.clientRutControl.disable();
    }
  }

  public async ngOnInit() {
    this.clientForm.get('operation').valueChanges.subscribe((option) => this.changeOptionSelected(option));

    if (this.isBiotabletValidation) this.validationInfo = { executive: JSON.parse(localStorage.getItem('validationInfoExecutive')) };
    if (this.shouldGetClientDataFromFirebase) this.saveClient();
  }

  public async saveClient() {
    if (this.isBiotabletValidation && this.operation !== this.biometricOperations.EMAIL_SECURITY_QUESTIONS &&
      !this.shouldGetClientDataFromFirebase) return this.emitBiotebletValidation();
    const sessionData = await this.authenticationService.signInAnonymously();
    if (!sessionData || !sessionData.token) return;
    this.buildClientObject();
  }

  public async validateEmailDomain() {
    const emailControl = this.clientForm.controls['email'];
    const email = this.clientForm.value['email'];
    if (this.invalidEmail('email') || !email) return;
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email);
    if (!validDomain) return emailControl.setErrors({ invalidDomain: true });
  }

  public getErrorMessage(controlName: string) {
    const control = this.clientForm.controls[controlName];

    if (control.hasError('required')) {
      return ERROR_MESSAGES_FORM['required'];
    }
    if (this.invalidEmail(controlName)) {
      return ERROR_MESSAGES_FORM['invalidEmail'];
    }
    if (this.invalidPhone(controlName)) {
      return ERROR_MESSAGES_FORM['invalidPhone'];
    }
    if (control.hasError('invalidDomain')) {
      return ERROR_MESSAGES_FORM['invalidDomain'];
    }
    return '';
  }

  public goBack() {
    this.status.emit('executiveValidation');
    this.userType.emit('executive');
  }

  private invalidEmail(controlName: string) {
    const control = this.clientForm.controls[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }

  private invalidPhone(controlName: string) {
    const control = this.clientForm.controls[controlName];
    return control.hasError('minlength') || control.hasError('maxlength') ||
      (control.hasError('pattern') && controlName === 'phone');
  }

  public get shouldGetClientDataFromFirebase(): boolean {
    const functionality = localStorage.getItem(FUNCTIONALITY);
    const pendingAuth = localStorage.getItem(PENDING_AUTH);
    return (this.isBiotabletValidation && functionality === 'productOpening' && (pendingAuth !== undefined && pendingAuth !== null));
  }

  public get shouldAskClientName(): boolean {
    return this.operation !== this.biometricOperations.BIOMETRIC_VALIDATION && this.operation !== this.biometricOperations.BARCODE &&
      this.operation !== 'default';
  }

  private buildClientObject() {
    const isCodeValidation = this.operation === VALIDATION_OPERATIONS.EMAIL_SECURITY_QUESTIONS;
    const isBiometricValidation = this.operation === VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION;
    const isFacialValidation = this.operation === VALIDATION_OPERATIONS.FACIAL_VALIDATION;
    if (this.shouldGetClientDataFromFirebase) return this.managePendingAuth();
    this.setClientDataFromForm(isCodeValidation);
    if (!isBiometricValidation) this.setAnexo();
    this.emitData(isCodeValidation, isBiometricValidation, isFacialValidation);
  }

  private managePendingAuth() {
    this.loadingProvider.showLoading();
    const pendingAuth = localStorage.getItem(PENDING_AUTH);
    this.currentValidationRef = this.firebaseDatabase.object(`client/${pendingAuth}`);
    this.currentValidationRef.valueChanges().subscribe((data) => {
      this.clientPhoneValidationObject.data = {
        rut: data.rut,
        email: data.email,
        phone: data.phone,
        name: data.name,
        attempts: data.attempts,
        date: data.date,
        executiveRut: data.executiveRut,
        ring: data.ring,
        status: data.status,
      } as ClientValidationData;
      this.clientPhoneValidationObject.status = 'validation';
      this.setAnexo();
      this.emitData(true, false, false);
      this.loadingProvider.hideLoading();
    });
  }

  private changeOptionSelected(option) {
    this.operation = option;
    this.changeControl();
  }

  private changeControl() {
    this.setControls();
    switch (this.operation) {
      case VALIDATION_OPERATIONS.SECURITY_QUESTIONS:
        return this.clientForm.removeControl('email');

      case VALIDATION_OPERATIONS.BIOMETRIC_VALIDATION:
      case VALIDATION_OPERATIONS.BARCODE:
        this.clientForm.removeControl('email');
        this.clientForm.removeControl('name');
        return this.clientForm.removeControl('phone');
    }
  }

  private setControls() {
    const emailValidator = [Validators.email, Validators.pattern(EMAIL_PATTERN), Validators.required];
    const phoneValidator = [Validators.maxLength(this.maxLength), Validators.minLength(this.minLength), Validators.pattern(PHONE_PATTERN)];
    this.clientForm.addControl('email', new FormControl('', emailValidator));
    this.clientForm.addControl('phone', new FormControl('', phoneValidator));
    this.clientForm.addControl('name', new FormControl(''));
  }

  private setClientDataFromForm(isCodeValidation: boolean) {
    const origin = isCodeValidation ? TRANSFER_ORIGINS.BIODESKTOP_CODE
      : this.operation === VALIDATION_OPERATIONS.FACIAL_VALIDATION
        ? TRANSFER_ORIGINS.BIODESKTOP_FACI : TRANSFER_ORIGINS.BIODESKTOP_RING;
    sessionStorage.setItem('originTransfer', origin);

    const traceId = this.util.setAndGetTraceId();
    const { rut, email, phone, name } = this.clientForm.value;
    this.clientPhoneValidationObject.data = {
      rut,
      email: isCodeValidation ? email : origin === TRANSFER_ORIGINS.BIODESKTOP_FACI ? email : '',
      phone,
      name,
      attempts: 0,
      date: Date.now(),
      executiveRut: this.validationInfo.executive.rut,
      ring: isCodeValidation, // Está al reves
      status: isCodeValidation ? TRANSFER_STATES.ASSISTED_PENDING : TRANSFER_STATES.PENDING,
      origin,
      traceId,
    } as ClientValidationData;
    localStorage.setItem('assistedEmail', this.clientPhoneValidationObject.data.email);
    localStorage.setItem('assistedPhone', String(this.clientPhoneValidationObject.data.phone));
    this.clientPhoneValidationObject.status = 'validation';
  }

  private async setAnexo() {
    const refAnexo = this.firebaseDatabase.object(`executive_anexo/${this.validationInfo.executive.rut}`);
    this.clientPhoneValidationObject.data.anexo = await refAnexo.valueChanges().pipe(first()).toPromise() as string;
    if (!this.clientPhoneValidationObject.data.anexo) this.setDefaultAnexo();
    localStorage.setItem('clientPhoneValidationObject', JSON.stringify(this.clientPhoneValidationObject));
  }

  private setDefaultAnexo() {
    this.clientPhoneValidationObject.data.anexo = '9999';
  }

  private emitData(isCodeValidation: boolean, isBiometricValidation: boolean, isFacialValidation: boolean) {
    this.status.emit('loading');
    this.userType.emit('client');
    this.clientPhoneValidation.emit(this.clientPhoneValidationObject);
    this.isBiometricValidation.emit(isBiometricValidation);
    this.isCodeValidation.emit(isCodeValidation);
    this.isFacialValidation.emit(isFacialValidation);
  }

  private emitBiotebletValidation() {
    this.biotabletValidation.emit({ rut: this.clientRutControl.value, operation: this.operation });
  }

  private checkIfClientIsBeingVerified() {
    const pendingKey = localStorage.getItem(PENDING_AUTH);
    if (!pendingKey) return;
    const clientPhoneValidationObject = JSON.parse(localStorage.getItem('clientPhoneValidationObject')) as ClientPhoneValidation;
    localStorage.getItem('isCodeValidation');
    this.clientPhoneValidation.emit(clientPhoneValidationObject);
    this.status.emit('loading');
    this.userType.emit('client');
  }

}
