import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { WhatsappResultModalComponent, WhatsappResultModalStatus } from '@components/whatsapp-result-modal/whatsapp-result-modal.component';
import { MAX_LENGTH_RUT, TRANSFER_ORIGINS } from '@constants';
import { GoogleAnalyticsProvider } from '@providers/googleAnalytics/googleAnalytics';
import { LoadingProvider } from '@providers/loading/loading';
import { ModalProvider } from '@providers/modal/modal';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Util } from '@util';
import { ValidateRut } from 'app/validators/rut.validator';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public maxLengthForSecurityKey = 20;
  public maxLengthForRut = MAX_LENGTH_RUT;

  private paramTicket: string;
  private paramRut: string;

  constructor(
    private formBuilder: FormBuilder,
    private util: Util,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private loadingProvider: LoadingProvider,
    private googleAnalyticsProvider: GoogleAnalyticsProvider,
    private modalProvider: ModalProvider,
    private renderer: Renderer2,
  ) {
    this.loginForm = this.formBuilder.group({
      rut: ['', [Validators.required, ValidateRut]],
      securityKey: ['', [Validators.required, Validators.maxLength(this.maxLengthForSecurityKey)]],
    });
    this.googleAnalyticsProvider.registerPageView('online');
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'recaptcha');
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.paramTicket = queryParams['ticket'];
      this.paramRut = queryParams['rut'];
      this.loginForm.get('rut').setValue(this.util.rutFormat(this.paramRut));
    });
    this.renderer.addClass(document.body, 'recaptcha');
  }

  get isProductionEnvironment(): boolean {
    return this.util.isProductionEnvironment();
  }

  get form() {
    return this.loginForm.controls;
  }

  get disableSacuForm() {
    return this.loginForm.invalid || !this.loginForm.dirty || !this.loginForm.touched;
  }

  get rutSacu() {
    return this.loginForm.controls['rut'] as FormControl;
  }

  public login(): void {
    if (this.loginForm.invalid) return;
    this.util.setAndGetTraceId();
    this.loadingProvider.showLoading();
    this.authenticationService.messagingAccountsLogin({...this.loginForm.value, ticket: this.paramTicket})
    .then((response) => {
        if (response.success) {
          this.loginSuccessHandler();
          return;
        }
        this.handleError();
      })
      .catch(() => this.handleError())
      .finally(() => this.loadingProvider.hideLoading());
  }

  public handleError(): void {
    this.matDialog.open(WhatsappResultModalComponent, {
      panelClass: 'mat-dialog-container-border',
      data: { resultStatus: false } as WhatsappResultModalStatus,
    });
  }

  public openOtherAfpsContactModal() {
    this.modalProvider.openOtherAfpsContactModal();
  }

  private async loginSuccessHandler(): Promise<void> {
    sessionStorage.setItem('originTransfer', TRANSFER_ORIGINS.SACU_PASSWORD);
    this.matDialog.open(WhatsappResultModalComponent, {
      panelClass: 'mat-dialog-container-border',
      data: { resultStatus: true } as WhatsappResultModalStatus,
    });
  }

  public securityKeyIsNotValid(): boolean {
    return this.form.securityKey.hasError('maxlength') && this.form.securityKey.touched;
  }

  public securityKeyIsWrong(): boolean {
    return this.form.securityKey.hasError('wrongsecurityKey') && this.form.securityKey.touched;
  }

}
