import { Component, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { INVESTOR_SIMULATOR_QUESTIONS, TEXT_CONTENT, INVESTOR_SIMULATOR_RESULT, CONTROL_NAMES } from 'util/investor-simulator.constant';
import { Util } from '@util';
import { Location } from '@angular/common';

import { InvestorProfileBox, InvestorProfileResult, InvestorProfileResultRequest } from '@interfaces/investor-profile.interface';
import { InvestorProfileService } from '@services/investor-profile/investor-profile.service';
import { BIO_TABLET_ORIGIN } from '@constants';
import { CLIENT_DATA } from 'util/storage.constants';
import { ClientDataResponse } from '@interfaces/clientData.interface';
import { InactivityService } from '@services/inactivityTime/inactivityTime.service';

@Component({
  selector: 'app-investor-profile-box',
  templateUrl: './investor-profile-box.component.html',
  styleUrls: ['./investor-profile-box.component.scss'],
})
export class InvestorProfileBoxComponent implements OnInit {
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  @Output() public backToInit = new EventEmitter<boolean>();

  private controlNames = CONTROL_NAMES;
  public boxData: InvestorProfileBox;
  public clientData: ClientDataResponse;
  public questions = INVESTOR_SIMULATOR_QUESTIONS;
  public textContent = TEXT_CONTENT;
  public profile: InvestorProfileResult;
  public loadingSimulationResultId;
  public simulationResultId: number;
  public form = new FormGroup({
    answers: new FormGroup({
      first: new FormControl('', Validators.required),
      second: new FormControl('', Validators.required),
      third: new FormControl('', Validators.required),
      fourth: new FormControl('', Validators.required),
      fifth: new FormControl('', Validators.required),
      sixth: new FormControl('', Validators.required),
    }),
  });

  public get stepIndex() {
    return this.stepper ? this.stepper.selectedIndex : 0;
  }
  public get answersGroup() {
    return this.form.get('answers') as FormGroup;
  }

  constructor(
    public util: Util,
    private investorProfileService: InvestorProfileService,
    private location: Location,
    private inactivityService: InactivityService
  ) { }

  public ngOnInit() {
    this.inactivityService.startInactivityPostVentaService();
    localStorage.setItem('investorProfile', JSON.stringify({ option: 'simulator' }));
    localStorage.setItem('originAffiliate', 'investorProfile');
    this.clientData = JSON.parse(localStorage.getItem(CLIENT_DATA));
    this.boxData = {
      title: '',
      description: '',
      fullName: `${this.clientData.name} ${this.clientData.lastName} ${this.clientData.motherLastName}`,
      rut: this.clientData.rut,
      email: this.clientData.email,
      phone: this.clientData.cellphoneNumber,
    };
  }

  public get fundBackgroundClass() {
    if (!this.profile) {
      return '';
    }
    const { fund } = this.profile;
    return fund ? `fund-${fund.toLowerCase()}-background` : '';
  }

  public nextStep() {
    return this.stepper.next();
  }

  public answerControlName(index: number): string {
    return this.controlNames[index + 1];
  }

  public stepControl(index: number) {
    return this.answersGroup.get(`${index}`);
  }

  public invalidStepForm(index: number) {
    return this.answersGroup.get(this.answerControlName(index)).invalid;
  }

  public calculateInvestorProfile() {
    try {
      this.loadingSimulationResultId = true;
      this.nextStep();
      const answersFormValues = this.answersGroup.getRawValue();
      const values = Object.values(answersFormValues);
      const total = values.reduce((accumulate: number, currentValue: number) => accumulate + currentValue, 0) as number;
      this.setInvestorProfile(total);
      this.investorProfileService
        .sendAttentionCode({
          rut: this.boxData.rut,
          step: 1,
        })
        .subscribe();
    } catch (error) {
      this.investorProfileService
        .sendAttentionCode({
          rut: this.boxData.rut,
          step: -1,
        })
        .subscribe();
      throw new Error(error);
    }
  }

  private setInvestorProfile(score: number) {
    const results = [...INVESTOR_SIMULATOR_RESULT];
    results.reverse();
    results.forEach((profile) => {
      if (score <= profile.score) {
        this.profile = profile;
      }
    });
    this.saveSimulation();
  }

  private saveSimulation() {
    const data: InvestorProfileResultRequest = {
      name: this.boxData.fullName,
      rut: this.boxData.rut,
      email: this.boxData.email,
      phone: this.boxData.phone,
      simulatedFundResult: this.profile.fund.toUpperCase(),
      origin: BIO_TABLET_ORIGIN,
    };

    this.investorProfileService
      .saveSimulationResult(data)
      .toPromise()
      .then((res) => {
        this.simulationResultId = +res.id;
      })
      .catch(() => (this.simulationResultId = null))
      .finally(() => (this.loadingSimulationResultId = false));
  }

  public goBack() {
    this.location.back();
  }
}
